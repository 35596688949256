import { Component, Vue } from "vue-property-decorator";

import { SideNav } from "@/layout";
import { HomeworkModel } from "@/models";
import { Common } from "../Common";

export class PaperForm {
  name = '';
  date = '';
  city = '';
  school = '';
  class = '';
  teacher = '';
  grade = '';
  unit = '';
  level = '';
  num = 0;
  hid = '';
}

type response = { [key: string]: unknown };

@Component<AdminHomeworkPaperInfoController>({
  components: {
    SideNav
  }
})
export default class AdminHomeworkPaperInfoController extends Vue {
  /*定義data 變數 */
  private doubleClick = false;
  private paperForm = new PaperForm();
  private questionList: object = {};
  private token = window.localStorage.getItem('adminToken') as string;

  public async created() {
    const item = {
      hid: this.$route.query.hid,
      token: this.token,
    }
    const editData = await HomeworkModel.getPaperInfo(item) as response;
    this.assignDataToForm(editData);
  }

  private assignDataToForm(data: response) {
    Common.checkToken(data.ERR_CODE as number);

    const item = data.info as {
      hid: string;
      name: string;
      date: string;
      city: string;
      school: string;
      class: string;
      teacher: string;
      grade: string;
      unit: string;
      level: string;
      num: number;
    };
    this.paperForm.hid = item.hid;
    this.paperForm.name = item.name;
    this.paperForm.date = item.date;
    this.paperForm.city = item.city;
    this.paperForm.school = item.school;
    this.paperForm.class = item.class;
    this.paperForm.teacher = item.teacher;
    this.paperForm.grade = item.grade;
    this.paperForm.unit = item.unit;
    this.paperForm.level = item.level;
    this.paperForm.num = item.num;

    this.questionList = data.list as object;
  }
}
